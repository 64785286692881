export default [
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
  },
  {
    key: 'workingScheduleName',
    label: 'field.workingSchedule',
    localization: true,
    width: 6000,
  },
  {
    key: 'attendanceDate',
    label: 'field.attendanceDate',
  },
  {
    key: 'firstWorkingShift',
    label: 'field.firstWorkingShift',
  },
  {
    key: 'firstCheckinLate',
    label: 'field.firstCheckinLate',
  },
  {
    key: 'firstCheckoutEarly',
    label: 'field.firstCheckoutEarly',
  },
  {
    key: 'lastWorkingShift',
    label: 'field.lastWorkingShift',
  },
  {
    key: 'lastCheckinLate',
    label: 'field.lastCheckinLate',
  },
  {
    key: 'lastCheckoutEarly',
    label: 'field.lastCheckoutEarly',
  },
  {
    key: 'firstCheckinTime',
    label: 'field.firstCheckinTime',
  },
  {
    key: 'firstCheckinImage',
    label: 'field.firstCheckinImage',
    width: 10000,
  },
  {
    key: 'firstCheckinAddress',
    label: 'field.firstCheckinAddress',
    width: 10000,
  },
  {
    key: 'firstCheckinLatitude',
    label: 'field.firstCheckinLatitude',
  },
  {
    key: 'firstCheckinLongitude',
    label: 'field.firstCheckinLongitude',
  },
  {
    key: 'firstCheckoutTime',
    label: 'field.firstCheckoutTime',
  },
  {
    key: 'firstCheckoutImage',
    label: 'field.firstCheckoutImage',
    width: 10000,
  },
  {
    key: 'firstCheckoutAddress',
    label: 'field.firstCheckoutAddress',
    width: 10000,
  },
  {
    key: 'firstCheckoutLatitude',
    label: 'field.firstCheckoutLatitude',
  },
  {
    key: 'firstCheckoutLongitude',
    label: 'field.firstCheckoutLongitude',
  },
  {
    key: 'firstRemark',
    label: 'field.firstRemark',
    width: 10000,
  },
  {
    key: 'lastCheckinTime',
    label: 'field.lastCheckinTime',
  },
  {
    key: 'lastCheckinImage',
    label: 'field.lastCheckinImage',
    width: 10000,
  },
  {
    key: 'lastCheckinAddress',
    label: 'field.lastCheckinAddress',
    width: 10000,
  },
  {
    key: 'lastCheckinLatitude',
    label: 'field.lastCheckinLatitude',
  },
  {
    key: 'lastCheckinLongitude',
    label: 'field.lastCheckinLongitude',
  },
  {
    key: 'lastCheckoutTime',
    label: 'field.lastCheckoutTime',
  },
  {
    key: 'lastCheckoutImage',
    label: 'field.lastCheckoutImage',
    width: 10000,
  },
  {
    key: 'lastCheckoutAddress',
    label: 'field.lastCheckoutAddress',
    width: 10000,
  },
  {
    key: 'lastCheckoutLatitude',
    label: 'field.lastCheckoutLatitude',
  },
  {
    key: 'lastCheckoutLongitude',
    label: 'field.lastCheckoutLongitude',
  },
  {
    key: 'lastRemark',
    label: 'field.lastRemark',
    width: 10000,
  },
];
